@import '../../../app/styles/';
@import '../../../app/styles//variables';

.mainPage {
  display: flex;
  flex-direction: column;
  row-gap: $m-72;

  @include respond(tab-phone) {
    row-gap: $m-40;
  }

  @include respond(phone) {
    row-gap: $m-32;
  }
}
