@import '../../../../../../app/styles/mixins';
@import '../../../../../../app/styles/variables';

.samovyvozContainer {
  display: grid;
  gap: $m-16;
}

.samovyvoz {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
