@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.fullscreenPlayerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2100;
}

.playerWrapper {
  position: relative;

  .player {
    cursor: pointer;
    background-color: black;
  }

  .closeIconContainer {
    position: absolute;
    top: $m-24;
    right: $m-24;
  }
}
