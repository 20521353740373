@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.swiper {
  position: relative;
  overflow: hidden;
  width: 100%;

  .swiper-wrapper {
    display: flex;
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    width: 100%;
    gap: $m-8;
  }

  .swiper-pagination-bullet {
    cursor: pointer;
    background-color: $color-white;
    width: 16px;
    height: 16px;
    opacity: 1;
    border-radius: 11px;
    transition: all 0.2s;
  }

  .swiper-pagination-bullet-active {
    background-color: $color-primary;
    width: 32px;
    height: 16px;
    border-radius: 11px;
  }
}
