@import '../../../../app/styles/variables';
@import '../../../../app/styles/mixins';

.content {
  background-color: $color-light-gray;
  border-radius: $radius-16;
  padding: $m-40 $m-32;

  @include respond(phone) {
    padding: $m-24 $m-16;
  }
}

.title {
  margin-bottom: $m-24;
}

.info {
  display: grid;
  gap: $m-16;
}
