@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.remontPage {
  display: grid;
  gap: $m-72;
}

.title {
  text-align: center;
  margin-bottom: $m-72;

  @include respond(tab-port) {
    margin-bottom: $m-40;
  }

  @include respond(tab-phone) {
    margin-bottom: $m-32;
  }
}

.remontContainer {
  display: grid;
  grid-template-columns: auto 55%;
  gap: $m-32;

  img {
    width: 100%;
    object-fit: cover;
    border-radius: $radius-24;
  }

  @include respond(tab-land) {
    grid-template-columns: auto 45%;
  }

  @include respond(tab-port) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.info {
  display: flex;
  flex-direction: column;
  row-gap: $m-32;
}

.text {
  display: grid;
  row-gap: $m-16;
}

.btn {
  width: 50%;

  @include respond(tab-land) {
    width: 100%;
  }
}

.mapSection {
  h2 {
    text-align: center;
    margin-bottom: $m-40;
  }
}

.tablesSection {
  display: grid;
  gap: $m-40;
}
