@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.vopros {
  display: grid;
  gap: $m-32;

  @include respond(tab-port) {
    gap: $m-24;
  }
}

.voprosMainTitle {
  text-align: center;
  margin-bottom: $m-72;

  @include respond(tab-port) {
    margin-bottom: $m-40;
  }
}

.voprosContainer {
  display: grid;
  row-gap: $m-32;
}

.voprosParagraph {
  line-height: 130%;
  color: $color-white;
  letter-spacing: 0.5px;

  @include respond(tab-phone) {
    width: 100%;
  }
}
