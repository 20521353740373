@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.dropdownContainer {
  position: relative;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .dropdownTitleActive {
    color: $color-primary;
  }
}

.row {
  transition: all 0.3s ease;
  min-height: 20px;
  min-width: 20px;
}

.rowReverse {
  transform: rotate(180deg);
  transition: all 0.3s ease;
  min-height: 20px;
  min-width: 20px;

  & path {
    fill: $color-primary;
  }
}

.dropdownContent {
  overflow: hidden;
}
