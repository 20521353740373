@import '../../../../../app/styles/mixins';
@import '../../../../../app/styles/variables';

.loaderContainer {
  display: flex;
  gap: 4px;

  .loader {
    height: 16px;
    width: 46px;
    border-radius: $radius-8;
    background-color: $color-light-gray;

    @include respond(tab-phone) {
      width: 42px;
    }
  }
}
