@import '../../../../../../app/styles/mixins';
@import '../../../../../../app/styles/variables';

.modalActive {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  z-index: 2100;
  overflow: hidden;
}

.modalContent {
  position: relative;
  width: auto;
  max-height: 90vh;
  background-color: $color-gray;
  border: 2px solid $color-disabled;
  border-radius: $radius-24;
  overflow: hidden;
  cursor: grab;

  @include respond(phone) {
    width: 100vw;
    border: 1px solid $color-disabled;
  }

  img {
    width: 100%;
    max-height: 90vh;
    border-radius: $radius-24;
  }
}

.tools {
  display: flex;
  gap: $m-8;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;

  @include respond(phone) {
    right: $m-16;
    bottom: $m-16;
    left: unset;
    transform: none;
  }

  .button {
    display: flex;
    background-color: $color-disabled;
    color: $color-black;
    border-radius: $radius-8;
    padding: $m-8 $m-16;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: $color-white;
    }

    @include respond(phone) {
      padding: $m-8 $m-8;
      background-color: $color-white;
    }
  }
}
