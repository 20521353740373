@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.checkboxContainer {
  position: relative;
  display: flex;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  column-gap: $m-12;
  font-size: $fs-body-16;
  cursor: pointer;

  @include respond(tab-port) {
    font-size: $fs-body-12;
  }
}

.inputContainer {
  height: 24px;
  width: 24px;
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: inherit;
  width: inherit;
  background-color: transparent;
  border: 1px solid $color-white;
  border-radius: 4px;

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 8px;
    top: 4px;
    width: 6px;
    height: 12px;
    border: solid $color-white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.checkbox:checked + .checkmark {
  &:after {
    display: block;
  }
}
