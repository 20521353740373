@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.cardContainer {
  display: grid;
  grid-template-rows: 60% 40%;
  overflow: hidden;

  height: 512px;
  border-radius: $radius-16;
  background-color: $color-black;
  border: 2px solid $color-light-gray;
  transition: all 0.5s;

  @include respond(tab-phone) {
    height: 360px;
    grid-template-rows: 54% 46%;
  }
}

.photoContainer {
  position: relative;
}

.imgLinkContainer {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: $color-light-gray;
}

.photoCard {
  height: inherit;
  width: inherit;
  object-fit: cover;
  cursor: pointer;
}

.favoriteIcon {
  position: absolute;
  top: $m-8;
  right: $m-8;
  cursor: pointer;

  & svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    path {
      fill: $color-primary;
    }
  }
}

.badge {
  position: absolute;
  z-index: 2;
  left: $m-8;
  top: $m-8;
}

.descriptionContainer {
  font-size: $fs-body-16;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $m-8 $m-8 $m-24 $m-8;

  @include respond(tab-phone) {
    font-size: $fs-body-14;
    padding-bottom: $m-12;
  }
}

.cardTextContainer {
  display: flex;
  flex-direction: column;
  row-gap: $m-8;
}

.cardPrices {
  display: flex;
  flex-wrap: wrap;
  column-gap: $m-8;
}

.cardPrice {
  color: $color-primary;
}

.cardOldPrice {
  font-size: $fs-body-14;
  text-decoration: line-through;
}

.cardDiscount {
  color: $color-primary;
}

.textCard {
  cursor: pointer;
  height: 62px;
  overflow: hidden;
  line-height: 130%;
  text-decoration: none;
  color: $color-white;

  &:hover {
    text-decoration: underline;
  }

  @include respond(tab-phone) {
    line-height: 14px;
    font-size: 12px;
    height: 42px;
  }
}
