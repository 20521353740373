@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.title {
  text-align: center;
  margin-bottom: $m-72;

  @include respond(tab-port) {
    margin-bottom: $m-40;
  }

  @include respond(tab-phone) {
    margin-bottom: $m-24;
  }
}

.emptyFavoriteContainer {
  display: flex;
  gap: $m-32;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & a {
    width: fit-content;
    margin: 0 auto;
  }
}
