@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.containerWorkLeft {
  display: flex;
  flex-direction: row-reverse;
  gap: $m-32;

  @include respond(tab-port) {
    flex-direction: column;
    row-gap: $m-16;
  }
}

.containerWorkRight {
  display: flex;
  gap: $m-32;

  @include respond(tab-port) {
    flex-direction: column;
    row-gap: $m-16;
  }
}

.titleRight {
  margin-bottom: $m-24;

  @include respond(tab-phone) {
    margin-bottom: $m-8;
  }
}

.titleLeft {
  margin-bottom: $m-24;
  text-align: right;

  @include respond(tab-port) {
    text-align: left;
  }

  @include respond(tab-phone) {
    margin-bottom: $m-8;
  }
}

.textRight {
  text-align: right;

  @include respond(tab-port) {
    text-align: left;
  }
}

.workInfo {
  display: flex;
  flex-direction: column;
  gap: $m-24;
  width: 40%;

  @include respond(tab-land) {
    gap: $m-16;
    width: 50%;
  }

  @include respond(tab-port) {
    width: 100%;
  }

  @include respond(tab-phone) {
    gap: $m-8;
  }
}

.image {
  width: 60%;
  height: 60%;

  @include respond(tab-land) {
    width: 50%;
  }

  @include respond(tab-port) {
    width: 100%;
  }
}

.button {
  width: 100%;
}
