@import '../../../app/styles/variables';
@import '../../../app/styles/mixins';

.carouselItemsHeader {
  display: flex;
  align-items: center;
  margin-bottom: $m-32;
  justify-content: space-between;

  @include respond(phone) {
    margin-bottom: $m-16;
  }
}

.carouselItemsHeader h2 {
  font-weight: 400;
  color: $color-white;
}

.linkContainer {
  cursor: pointer;
  color: $color-disabled;
  display: flex;
  align-items: center;
  transition: all 200ms;
  text-decoration: none;

  &:hover {
    color: $color-white;
  }
}
