@import '../../../../../../app/styles/mixins';
@import '../../../../../../app/styles/variables';

.calculateTotalCOntainer {
  display: flex;
  justify-content: space-between;
}

.sell {
  display: flex;
  flex-direction: column;
  row-gap: $m-16;

  @include respond(tab-phone) {
    row-gap: $m-8;
  }
}

.prices {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.colorPrimary {
  color: $color-primary;
}
