@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 600px) {
      @content;
    }
  }

  @if $breakpoint == tab-phone {
    @media only screen and (max-width: 767px) {
      @content;
    }
  }

  @if $breakpoint == tab-port {
    @media only screen and (max-width: 1023px) {
      @content;
    }
  }

  @if $breakpoint == tab-land {
    @media only screen and (max-width: 1280px) {
      @content;
    }
  }

  @if $breakpoint == big-screen {
    @media only screen and (max-width: 1600px) {
      @content;
    }
  }
}

@mixin style-link($color) {
  text-decoration: none;
  color: $color;
  transition: all 0.2s;
}
