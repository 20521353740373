@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.header {
  padding: $m-24 $m-40;
  background-color: $color-black;
  z-index: 2000;

  @include respond(tab-land) {
    position: sticky;
    width: 100vw;
    top: 0;
  }

  @include respond(tab-port) {
    padding: $m-16 $m-32;
  }

  @include respond(tab-phone) {
    padding: $m-16 $m-8;
  }

  .headerContainer {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    justify-content: space-between;
    align-items: center;

    @include respond(tab-land) {
      grid-template-columns: 1fr 1fr;
    }

    @include respond(phone) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .buttons {
    display: flex;
    column-gap: $m-24;
    justify-content: flex-end;
    align-items: center;
  }
}
