@import '../../../../../app/styles/variables';
@import '../../../../../app/styles/mixins';

.companyInfo {
  color: $color-disabled;
  font-size: $fs-body-12;
  line-height: 140%;
  text-align: right;

  @include respond(tab-phone) {
    text-align: center;
  }
}
