@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.mapSection {
  display: grid;
  grid-template-columns: auto 35%;
  gap: $m-32;

  @include respond(big-screen) {
    grid-template-columns: auto 40%;
  }

  @include respond(tab-land) {
    grid-template-columns: 1fr 1fr;
  }

  @include respond(tab-port) {
    display: flex;
    flex-direction: column;
    gap: $m-40;
  }

  @include respond(phone) {
    gap: $m-32;
  }
}
