@import '../../../../../app/styles/mixins';
@import '../../../../../app/styles/variables';

.buyer {
  display: flex;
  flex-direction: column;
  row-gap: $m-32;
  padding: $m-48 $m-32;
  border-radius: $radius-24;
  background-color: $color-light-gray;

  @include respond(tab-port) {
    border-radius: $radius-16;
  }

  @include respond(tab-phone) {
    padding: $m-24 $m-16;
  }
}

.info {
  display: flex;
  flex-direction: column;
  row-gap: $m-16;
}

.textInfo {
  line-height: 24px;
}
