@import '../../../../app/styles/mixins';

.brand {
  .container {
    width: fit-content;
    margin: 0 auto;
  }

  @include respond(tab-phone) {
  }

  .logo {
    height: 68px;
    width: 68px;

    @include respond(tab-phone) {
      height: 55px;
      width: 55px;
    }
  }

  .sublogo {
    height: 40px;
    width: 160px;
    align-self: center;
    margin-left: 10px;

    @include respond(tab-phone) {
      height: 32px;
      width: 130px;
    }
  }
}

.link {
  display: flex;
  justify-content: center;
}
