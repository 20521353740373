@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.errorContainer {
  height: fit-content;
  border-radius: 16px;
}

.contentError {
  width: 80%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respond(tab-phone) {
    width: 100%;
  }
}

.errorMessage {
  text-align: center;
  max-width: 500px;
  margin: 50px 0;
  line-height: 26px;
  letter-spacing: 0.02cm;
}
