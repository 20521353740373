@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.paymentMainTitle {
  text-align: center;
  margin-bottom: $m-72;

  @include respond(tab-port) {
    margin-bottom: $m-40;
  }
}

.paymentSubtitle {
  color: $color-primary;
  text-align: center;
  margin-bottom: 60px;
}

.paymentBox {
  margin-bottom: 40px;
}

.paymentTitle {
  margin-bottom: 20px;
}

.paymentParagraph {
  line-height: 130%;
  letter-spacing: 0.5px;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  &_mainColor {
    color: $color-primary;
  }
}

.paymentParagraphh {
  line-height: 130%;
  letter-spacing: 0.5px;
  color: $color-primary;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.paymentList {
  padding-left: 15px;
  color: $color-light-gray;
  display: flex;
  column-gap: 40px;
  margin-bottom: 15px;

  @include respond(phone) {
    display: block;
  }
}

.paymentListItem {
  color: $color-white;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.paymentBboxx {
  margin-bottom: 60px;
}
