@import '../../../../../app/styles/mixins';
@import '../../../../../app/styles/variables';

.cartsContainer {
  border-radius: $radius-24;
  background-color: $color-light-gray;
  padding: $m-48 $m-32;

  @include respond(tab-port) {
    padding: $m-48 $m-32;
    border-radius: $radius-16;
  }

  @include respond(tab-phone) {
    padding: $m-24 $m-16;
  }
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: $m-32;
  border-bottom: 1px solid $color-white;

  @include respond(tab-phone) {
    padding-bottom: $m-16;
  }
}

.cartContainerSkelleton {
  width: 100%;
  height: 500px;
  border-radius: $radius-16;
  background-color: $color-disabled;
  opacity: 0.1;

  @include respond(tab-phone) {
    height: 350px;
  }
}
