@import '../../../../../app/styles/variables';
@import '../../../../../app/styles/mixins';

.icon {
  transition: all 200ms ease-in-out;
  color: $color-white;
  cursor: pointer;

  &:hover {
    color: $color-primary;
  }
}
