@import '../../../../../app/styles/mixins';
@import '../../../../../app/styles/variables';

.itemContainer {
  display: grid;
  grid-template-columns: 49% 1fr;
  gap: $m-32;
  margin: 0;
  opacity: 0.1;

  @include respond(tab-port) {
    grid-template-columns: none;
  }
}

.photoSlider {
  width: 100%;
  height: 100%;
  border-radius: $radius-16;
  background-color: $color-disabled;
  border: 2px solid $color-disabled;

  @include respond(tab-port) {
    height: 50vh;
  }
}

.itemInfo {
  display: flex;
  flex-direction: column;
  gap: $m-24;
}

.title {
  width: 80%;
  height: 8vh;
  background-color: $color-disabled;
  color: transparent;
  border-radius: $radius-16;
}

.bonus {
  width: 60%;
  height: 8vh;
  background-color: $color-disabled;
  color: transparent;
  border-radius: $radius-16;
}

.prices {
  display: flex;
  align-items: baseline;
  gap: $m-24;
}

.price {
  width: 30%;
  height: 5vh;
  background-color: $color-disabled;
  color: transparent;
  border-radius: $radius-16;
}

.description {
  background-color: $color-disabled;
  height: 30vh;
  border-radius: $radius-16;
}

.oldPrice {
  width: 30%;
  height: 3vh;
  background-color: $color-disabled;
  color: transparent;
  border-radius: $radius-16;
}

.itemButtons {
  display: flex;
  gap: $m-24;

  @include respond(tab-port) {
    flex-direction: column;
  }
}

.button {
  width: 100%;
  height: 5vh;
  background-color: $color-disabled;
  color: transparent;
  border-radius: $radius-16;
}
