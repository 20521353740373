@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.repairTable {
  h2 {
    text-align: center;
    margin-bottom: $m-24;
  }
}

.table {
  border-collapse: collapse;
  width: 100%;

  @include respond(tab-phone) {
    font-size: $fs-body-12;
  }

  thead {
    border-bottom: 1px solid $color-white;
  }

  .nameCell {
    text-align: left;
    padding: $m-8 0;
  }

  .centerCell {
    width: 150px;
    text-align: center;
    border-left: 1px solid $color-white;
    padding: $m-8 0;

    &:not(:last-child) {
      border-right: 1px solid $color-white;
    }

    @include respond(tab-phone) {
      width: 20%;
    }
  }
}
