@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.closeIcon {
  cursor: pointer;

  &:hover {
    path {
      stroke: $color-primary;
    }
  }
}
