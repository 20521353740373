@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.catalogContainer {
  display: grid;
  grid-template-columns: 280px auto;
  column-gap: $m-32;

  @include respond(tab-port) {
    display: block;
  }
}

.sortMobileFilter {
  display: flex;
  justify-content: space-between;
  margin-bottom: $m-32;

  @include respond(tab-phone) {
    margin-bottom: $m-16;
  }
}
