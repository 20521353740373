@import '../../../app/styles/mixins.scss';
@import '../../../app/styles/variables.scss';

.catalogContainer {
  display: grid;
  grid-template-columns: 280px auto;
  column-gap: $m-32;

  @include respond(tab-port) {
    display: block;
  }
}

.sortMobileFilter {
  display: flex;
  justify-content: space-between;
  margin-bottom: $m-32;

  @include respond(tab-phone) {
    margin-bottom: $m-16;
  }
}

.catalogTitleContainer {
  display: flex;
  column-gap: $m-16;
  margin-bottom: $m-72;
  justify-content: center;

  @include respond(tab-phone) {
    flex-direction: column;
    margin-bottom: $m-24;
    row-gap: $m-8;
    align-items: center;

    h1 {
      text-align: center;
    }
  }
}

.catalogDescription {
  text-align: center;
  margin-top: $m-48;
}
