@import '../../../../../../../app/styles/mixins';
@import '../../../../../../../app/styles/variables';

.recievContainer {
  display: flex;
  flex-direction: column;
  row-gap: $m-12;
  padding: $m-24;
  box-shadow: 0px 0px 0px 1px $color-white inset;
  border-radius: $radius-24;
  cursor: pointer;
  transition: color 200ms ease;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 0px 0px 1px $color-primary inset;

    .title {
      color: $color-primary;
    }
  }

  @include respond(tab-phone) {
    padding: $m-16;
    border-radius: $radius-16;
  }
}

.recievContainerActive {
  display: flex;
  flex-direction: column;
  row-gap: $m-12;
  padding: $m-24;
  box-shadow: 0px 0px 0px 2px $color-primary inset;
  border-radius: $radius-24;
  transition: color 200ms ease;
  overflow: hidden;

  @include respond(tab-phone) {
    padding: $m-16;
    border-radius: $radius-16;
  }

  .title {
    color: $color-primary;
  }

  .adresContainer {
    display: flex;
    margin-top: $m-16;
  }
}

.childrenContainer {
  padding-top: $m-24;
}
