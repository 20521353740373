@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.container {
  display: grid;
  gap: $m-24;

  .message {
    line-height: $m-24;
  }

  @include respond(tab-phone) {
    display: grid;
    text-align: center;
    gap: $m-24;
  }
}

.isManelyakMessage {
  font-size: $fs-body-14;
  width: fit-content;
  border-radius: $radius-16;
  border: 2px solid $color-primary;
  padding: 4px $m-8;

  @include respond(tab-phone) {
    font-size: $fs-body-12;
  }
}
