@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.ordersContainer {
  width: auto;
  display: flex;
  flex-direction: column;
  row-gap: $m-24;
}

.ordersEmptyContainer {
  background-color: $color-light-gray;
  border-radius: $radius-24;
  padding: $m-24;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: $m-24;

  @include respond(tab-phone) {
    border-radius: $radius-16;
  }
}
