@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.dostavkaMainTitle {
  text-align: center;
  margin-bottom: $m-72;
  font-size: $fs-title-32;

  @include respond(tab-port) {
    margin-bottom: $m-40;
  }
}

.dostavkaContainer {
  display: grid;
  row-gap: $m-40;
  margin: 0 auto;
}

.dostavkaTitle {
  width: 80%;
  margin-bottom: 20px;
}

.dostavkaParagraph {
  line-height: 130%;
  color: $color-white;
  letter-spacing: 0.5px;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.dropdownBox {
  display: grid;
  gap: $m-24;
}

.dropdownLogoBox {
  display: flex;
  align-items: center;
  column-gap: 20px;
  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @include respond(tab-phone) {
    display: block;
  }
}

.dropdownLogoText {
  font-size: 18px;
  color: $color-white;
  & p {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.dropdownImage {
  width: 227px;
  height: 110px;

  @include respond(tab-phone) {
    margin-bottom: 20px;
  }
}

.dropdownLogoBoxx {
  width: 227px;
  height: 65px;

  @include respond(tab-phone) {
    margin-bottom: 20px;
  }
}

//Внимание
.dostavkaWarning {
  padding: 20px;
  border: 2px dashed $color-white;
  border-radius: 16px;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.5px;
}
