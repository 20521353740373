@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.itemPhotoes {
  position: sticky;
  height: fit-content;
  top: $m-16;

  @include respond(tab-port) {
    position: unset;
  }
}

.itemMainPhotoBox {
  position: relative;
  overflow: hidden;
  border: 2px solid $color-disabled;
  border-radius: $radius-24;

  @include respond(tab-port) {
    height: 70%;
  }

  @include respond(tab-phone) {
    border: 1px solid $color-disabled;
    height: 100%;
  }
}

.discountBage {
  position: absolute;
  z-index: 2;
  padding: 5px 10px 3px 12px;
  color: $color-gray;
  text-align: center;
  border-radius: 16px;
  background-color: $color-primary;
  left: 20px;
  top: 20px;
}

.favoriteIcon {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 20px;
  width: 24px;
  height: 24px;

  &:hover {
    path {
      fill: $color-primary;
    }
  }
}

.badge {
  position: absolute;
  z-index: 2;
  left: 20px;
  top: 20px;
}

.itemSecondaryPhotoes {
  display: flex;
  margin-top: $m-16;
}
