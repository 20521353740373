@import '../../../../../app/styles/variables';
@import '../../../../../app/styles/mixins';

.mobileNavbar {
  position: fixed;
  z-index: 1900;
  top: 86px;
  right: 0;
  padding: $m-16 $m-40 $m-16 $m-16;
  background-color: $color-black;
  border-left: 2px solid $color-gray;
  border-bottom: 2px solid $color-gray;
  border-radius: 0 0 0 $radius-16;
  overflow: hidden;

  @include respond(tab-phone) {
    top: 68px;
    padding: $m-32 $m-16 $m-16;
  }
}

.navbarList {
  display: flex;
  flex-direction: column;
  row-gap: $m-16;
}

.linkButton {
  height: fit-content;
  width: fit-content;
  text-decoration: none;
  color: $color-white;
  cursor: pointer;
  transition: all 0.2s;
  // Чтобы не прыгали ссылки
  height: 24px;

  &:hover {
    border-bottom: 1px solid $color-primary;
    padding-bottom: 4px;
  }
}

.linkButtonActive {
  height: fit-content;
  color: $color-primary;
  text-decoration: none;
  // Чтобы не прыгали ссылки
  height: 24px;
}
