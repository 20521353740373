@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.vozvratMainTitle {
  text-align: center;
  margin-bottom: $m-72;

  @include respond(tab-port) {
    margin-bottom: $m-40;
  }
}

.vozvratContainer {
  display: grid;
  row-gap: $m-32;
}

.vozvratParagraph {
  line-height: 130%;
  color: $color-white;
  letter-spacing: 0.5px;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.vozvratLink {
  text-decoration: none;
  color: $color-primary;
  cursor: pointer;
}
