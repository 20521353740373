@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.banner {
  display: grid;
  row-gap: $m-24;

  img {
    width: 100%;
    height: 512px;
    border-radius: $m-24;
    object-fit: cover;
  }
}

.info {
  display: grid;
  row-gap: $m-8;

  a {
    text-decoration: none;
    color: $color-white;
    width: fit-content;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $color-primary;
    }
  }
}

.title {
  @include respond(tab-port) {
    font-size: $fs-title-32;
  }

  @include respond(phone) {
    font-size: $fs-title-24;
  }
}

.text {
  width: 63%;
  line-height: $m-24;

  @include respond(tab-land) {
    width: 75%;
  }

  @include respond(tab-port) {
    width: 100%;
  }
}
