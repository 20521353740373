@import '../../../../../app/styles/mixins';
@import '../../../../../app/styles/variables';

.container {
  overflow: hidden;
  display: flex;
  column-gap: 24px;

  @include respond(tab-phone) {
    column-gap: 8px;
  }
}

.newItem {
  height: 512px;
  min-width: 247px;
  border-radius: 16px;
  background-color: $color-disabled;
  border: 2px solid $color-disabled;
  opacity: 0.1;

  @include respond(tab-phone) {
    height: 350px;
    min-width: 170px;
  }
}
