@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.SecondaryButton {
  padding: $m-16;
  background-color: transparent;
  color: $color-white;
  box-shadow: 0px 0px 0px 2px $color-white inset;
  border-radius: $radius-4;
  width: 100%;
  height: fit-content;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0px 0px 0px 2px $color-primary inset;
  }

  &:active {
    box-shadow: 0px 0px 0px 1px $color-primary inset;
  }

  &:disabled {
    color: $color-white;
    box-shadow: 0px 0px 0px 2px $color-white inset;
    box-shadow: none;
    opacity: 50%;
    cursor: default;
  }

  @include respond(tab-phone) {
    font-size: $fs-body-14;
    padding: $m-14;
  }
}
