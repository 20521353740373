@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.filterStink {
  position: sticky;
  top: $m-16;
  height: fit-content;

  @include respond(tab-port) {
    position: unset;
  }
}

.filterContainer {
  overflow: auto;
  height: 96vh;
  padding-right: $m-8;

  @include respond(tab-port) {
    background-blend-mode: multiply;
    background: $color-gray url(../../../../public/img/background.webp) center /
      cover no-repeat;
    height: 100dvh;
    width: 100vw;
    position: absolute;
    z-index: 2200;
    padding: 0;
    top: 0;
    left: 0;
    display: none;
    transition: height 0.25s linear;
  }
}

.active {
  display: block;
}

.mobileFilterHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-black;
  padding: $m-24 $m-32;

  @include respond(tab-phone) {
    padding: $m-16 $m-8;
  }
}

.formContainer {
  display: grid;
  flex-direction: column;
  row-gap: $m-32;

  @include respond(tab-port) {
    row-gap: $m-24;
    padding: $m-32 $m-32;
  }

  @include respond(tab-phone) {
    padding: $m-32 $m-8;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: $m-12;
  margin-top: $m-16;

  @include respond(tab-port) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

//Кнопка сброса фильтра
.mobileResetButton {
  color: $color-white;
  cursor: pointer;

  &:hover {
    color: $color-primary;
  }
}

//Инпуты
.inputContainer {
  display: flex;
  align-items: center;
  gap: $m-8;
  border-bottom: 1px solid $color-white;
  padding: $m-16 0px;
  width: 100%;
}

.input {
  background-color: transparent;
  color: $color-white;
  width: 100%;
}
