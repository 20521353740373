@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.map {
  background-color: $color-light-gray;
  border-radius: $radius-24;
  overflow: hidden;
  height: 723px;

  @include respond(tab-phone) {
    height: 70dvh;
  }

  .markerContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    .markerText {
      font-size: $fs-body-12;
      color: $color-gray;
      width: max-content;
      padding: $m-8;
      background-color: $color-primary;
      border-radius: $radius-8;
    }
  }
}
