@import '../../../../../app/styles/mixins';
@import '../../../../../app/styles/variables';

.item {
  display: flex;
  flex-direction: column;
  gap: $m-40;

  @include respond(tab-phone) {
    gap: $m-24;
  }

  .image {
    height: 512px;
    width: 100%;
    border-radius: $radius-24;
    background-color: $color-disabled;
    opacity: 0.1;
  }

  .title {
    height: 50px;
    width: 50%;
    border-radius: $radius-16;
    background-color: $color-disabled;
    opacity: 0.1;

    @include respond(tab-phone) {
      border-radius: $radius-8;
      width: 70%;
      height: 35px;
    }
  }
}
