@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.infoMainTitle {
  text-align: center;
  margin-bottom: $m-72;

  @include respond(tab-port) {
    margin-bottom: $m-40;
  }
}

.infoContainer {
  display: grid;
  row-gap: $m-32;

  h3 {
    text-align: left;
  }
}

.blockInfo {
  display: grid;
  gap: $m-40;
  text-align: center;
}

.infoDescription {
  display: grid;
  row-gap: $m-24;

  p {
    line-height: $m-24;
  }
}

.infoParagraph {
  text-align: start;
}
