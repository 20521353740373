@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.catalogPagination {
  display: flex;
  margin-top: $m-48;
  justify-content: center;

  @include respond(tab-phone) {
    margin-top: $m-24;
  }

  .MuiPagination-root {
    .Mui-selected {
      background-color: unset;
      border-radius: unset;
      border-bottom: 1px solid $color-primary;
    }

    button {
      color: $color-white;
    }

    div {
      color: $color-white;
    }
  }
}
