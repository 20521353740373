@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.carousel-item-swiper {
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: $m-24;

  @include respond(tab-phone) {
    grid-template-columns: repeat(auto-fill, minmax(156px, 1fr));
    gap: $m-16 $m-8;
  }

  .swiper-slide {
    min-width: 250px;

    @include respond(tab-phone) {
      min-width: 170px;
    }
  }
}
