@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.itemContainer {
  display: grid;
  grid-template-columns: 50% 1fr;
  gap: $m-32;
  margin-bottom: $m-64;

  @include respond(tab-land) {
    gap: $m-24;
    margin-bottom: $m-48;
  }

  @include respond(tab-port) {
    display: flex;
    flex-direction: column;
  }
}

.itemInfo {
  display: grid;
  align-content: flex-start;
  padding-top: $m-16;
  gap: $m-40;

  @include respond(tab-port) {
    align-content: unset;
    padding-top: unset;
    gap: $m-24;
  }
}
