@import '../../../../app/styles/variables';
@import '../../../../app/styles/mixins';

.mainButton {
  padding: $m-16;
  background-color: $color-primary;
  color: $color-gray;
  border-radius: $radius-4;
  width: 100%;
  height: 50.4px;
  min-height: 50.4px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;

  @include respond(tab-phone) {
    height: 44px;
    min-height: 44px;
  }

  &:hover {
    box-shadow: 0px 0px 0px 3px $color-white inset;
  }

  &:active {
    box-shadow: none;
  }

  &:disabled {
    background-color: $color-primary;
    color: $color-black;
    box-shadow: none;
    opacity: 50%;
    cursor: default;
  }

  @include respond(tab-phone) {
    padding: 0 4px;
  }
}

.mainLoadingButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-primary;
  border-radius: $radius-4;
  width: 100%;
  height: 50.4px;
  cursor: default;
  transition: all 0.3s;

  @include respond(tab-phone) {
    height: 44px;
  }
}
