@import '../../../../../app/styles/variables';
@import '../../../../../app/styles/mixins';

.footerNavbar {
  grid-template-rows: repeat(3, auto);
  display: grid;
  grid-auto-flow: column;
  row-gap: $m-24;
  column-gap: $m-64;

  @include respond(tab-land) {
    column-gap: $m-48;
  }

  @include respond(tab-port) {
    column-gap: $m-24;
  }

  @include respond(tab-phone) {
    row-gap: $m-16;
  }

  @include respond(phone) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
