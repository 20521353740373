@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.otzyvyMainTitle {
  text-align: center;
  margin-bottom: $m-72;

  @include respond(tab-phone) {
    width: 80%;
    margin: 0 auto $m-40;
  }
}
