@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.itemsBrandContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 240px;
  gap: $m-24;

  @include respond(tab-land) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond(tab-port) {
    grid-template-columns: 1fr;
  }

  @include respond(tab-phone) {
    grid-auto-rows: 190px;
  }
}

.mainTitle {
  margin-bottom: $m-48;

  @include respond(tab-phone) {
    margin-bottom: $m-16;
  }
}

.title {
  @include respond(tab-phone) {
    font-size: 20px;
  }
}

.itemBrandContainer {
  text-decoration: none;
  color: $color-white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
  justify-content: space-between;
  padding: $m-24;
  border-radius: $radius-24;
  background-color: $color-light-gray;
  transition: all 0.25s ease;

  &:hover {
    color: $color-primary;
  }

  @include respond(tab-phone) {
    padding: $m-16;
  }
}

.imgContainer {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  overflow: hidden;
}

.image {
  width: auto;
  border-radius: $radius-16;
  height: 100%;
  object-fit: cover;
}
