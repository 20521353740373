@import '../../../../../../app/styles/mixins';
@import '../../../../../../app/styles/variables';

.containerPost {
  display: flex;
  flex-direction: column;
  gap: $m-16;
}

.error {
  letter-spacing: 0.01cm;
  color: #a42121;
}

.inputContainer {
  position: relative;

  input {
    background-color: transparent;
    padding: $m-16 0;
    color: $color-white;
    width: 100%;
    border-bottom: 1px solid $color-white;

    &:focus {
      border-bottom: 1px solid $color-primary;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    padding: $m-16;
    background-color: $color-gray;
    border-radius: $radius-16;
    margin-top: $m-8;

    @include respond(tab-phone) {
      padding: $m-8;
    }

    button {
      text-align: left;
      padding: $m-16;
      color: $color-white;
      border-radius: $radius-8;
      transition: all 0.1s ease-in;
    }

    button:hover {
      background-color: $color-light-gray;
    }
  }

  .closeButton {
    position: absolute;
    background-color: $color-light-gray;
    right: 0;
    top: 16px;
    cursor: pointer;
  }
}
