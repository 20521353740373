@import '../../../../../app/styles/mixins';
@import '../../../../../app/styles/variables';

.mainSlider {
  border-radius: $radius-24;
  overflow: hidden;
}

.mainBannerSlider {
  height: 512px;
  display: flex;

  img {
    object-fit: cover;
    width: 100%;
    height: 512px;
  }
}
