@import '../../../../../app/styles/mixins';

.brand {
  .container {
    width: fit-content;
  }

  .logo {
    height: 55px;
    width: 55px;

    @include respond(phone) {
      height: 36px;
      width: 36px;
    }
  }

  .sublogo {
    height: 32px;
    width: 130px;
    align-self: center;
    margin-left: 10px;

    @include respond(phone) {
      display: none;
    }
  }
}

.link {
  display: flex;
  justify-content: center;
}
