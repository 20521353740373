@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.sortCatalogItems {
  display: grid;
  row-gap: $m-24;
  position: absolute;
  white-space: nowrap;
  top: 30px;
  left: -8px;
  background-color: $color-black;
  color: $color-gray;
  border-radius: $radius-16;
  padding: $m-16;
  z-index: 1;
}

.sortCatalogItem {
  color: $color-white;
  cursor: pointer;
  transition: all 100ms ease-in;

  &:hover {
    color: $color-primary;
  }
}

.active {
  color: $color-primary;
  cursor: pointer;
  transition: all 100ms ease-in;
}
