@import '../../../../../app/styles/variables';
@import '../../../../../app/styles/mixins';

.iconLink {
  @include respond(tab-phone) {
    display: none;
  }

  .icon {
    transition: all 200ms ease-in-out;
    color: $color-white;

    &:hover {
      color: $color-primary;
    }
  }
}

.iconLinkActive {
  @include respond(tab-phone) {
    display: none;
  }

  .icon {
    color: $color-primary;
  }
}
