@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.mobileMenu {
  width: 100vw;
  background-color: $color-black;
  border-top: 2px solid $color-gray;
  position: sticky;
  z-index: 2000;
  bottom: 0;
  justify-content: space-between;
  padding: 0 $m-16;
  display: flex;
}

.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 4px 6px;
  gap: 4px;
  color: $color-white;
  text-decoration: none;

  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    color: $color-white;
  }

  .iconTitle {
    font-size: $fs-body-12;
  }
}

.iconContainerActive {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 4px 6px;
  gap: 4px;
  text-decoration: none;

  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    color: $color-primary;
  }

  .iconTitle {
    font-size: $fs-body-12;
    color: $color-primary;
  }
}
