@import '../../../../../app/styles/mixins';
@import '../../../../../app/styles/variables';

.payment {
  height: fit-content;
  border-radius: $radius-24;
  background-color: $color-light-gray;
  padding: $m-48 $m-32;

  @include respond(tab-port) {
    border-radius: $radius-16;
  }

  @include respond(tab-phone) {
    padding: $m-24 $m-16;
  }
}

.title {
  margin-bottom: $m-32;

  @include respond(tab-phone) {
    text-align: center;
    margin-bottom: $m-24;
  }
}

.paymentMethods {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  gap: $m-24 $m-32;

  p {
    @include respond(tab-phone) {
      font-size: $fs-body-12;
    }
  }

  @include respond(tab-land) {
    grid-template-columns: none;
    justify-content: stretch;
  }
}

.paymentMethod {
  display: flex;
  flex-direction: column;
  row-gap: $m-8;
  padding: $m-24;
  box-shadow: 0px 0px 0px 1px $color-white inset;
  border-radius: $radius-24;
  cursor: pointer;
  transition: color 200ms ease;

  &:hover {
    box-shadow: 0px 0px 0px 1px $color-primary inset;
    h3 {
      color: $color-primary;
    }
  }

  @include respond(tab-phone) {
    padding: $m-16;
    border-radius: $radius-16;
  }
}

.active {
  display: flex;
  flex-direction: column;
  row-gap: $m-8;
  padding: $m-24;
  box-shadow: 0px 0px 0px 2px $color-primary inset;
  border-radius: $radius-24;
  cursor: pointer;
  transition: color 200ms ease;

  @include respond(tab-phone) {
    padding: $m-16;
    border-radius: $radius-16;
  }

  h3 {
    color: $color-primary;
  }
}
