@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.addresses {
  background-color: $color-light-gray;
  border-radius: $radius-24;
  padding: $m-48 $m-32;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include respond(phone) {
    padding: $m-48 $m-16;
  }
}

.title {
  text-align: center;

  @include respond(tab-port) {
    margin-bottom: $m-40;
  }
}

.addressesContainer {
  display: flex;
  row-gap: $m-32;
  flex-direction: column;

  @include respond(tab-port) {
    row-gap: $m-24;
    margin-bottom: $m-40;
  }
}

.contactsContainer {
  display: flex;
  flex-direction: column;
  gap: $m-32;
}

.socialMedia {
  display: flex;
}
