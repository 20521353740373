@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.categorySwiper {
  position: relative;
  overflow: hidden;

  .swiper-wrapper {
    display: flex;
  }

  .swiper-slide {
    display: flex;
    height: fit-content;
    align-items: center;
    flex-shrink: unset;
  }

  .swiper-pagination-bullet {
    background-color: #dcdcdc;
    width: 16px;
    height: 16px;
    opacity: 1;
    transition: all 0.2s;
  }

  .swiper-pagination-bullet-active {
    background-color: #e9b163;
    width: 32px;
    height: 16px;
    border-radius: 11px;
  }
}
