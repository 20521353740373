@import '../../../../../app/styles/mixins';
@import '../../../../../app/styles/variables';

.dropdownContainer {
  position: relative;
  background-color: $color-light-gray;
  padding: $m-24 $m-16;
  border-radius: $radius-24;
  cursor: pointer;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $fs-title-24;

  .dropdownTitleActive {
    color: $color-primary;
  }
}

.row {
  transition: all 0.3s ease;
}

.rowReverse {
  transform: rotate(180deg);
  transition: all 0.3s ease;

  & path {
    fill: $color-primary;
  }
}

.dropdownContent {
  overflow: hidden;
}
