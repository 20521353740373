@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.videoPlayerCard {
  height: 100px;
  width: 100px;
  transition: all 300ms ease;
  position: relative;

  @include respond(tab-phone) {
    height: 80px;
    min-width: 80px;
    max-width: 80px;
  }
}

.playIcon {
  position: absolute;
  z-index: 1;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & path {
    fill: $color-white;
    stroke: $color-white;
    transition: all 300ms ease;
  }
}

.img {
  width: 100%;
  height: auto;
}
