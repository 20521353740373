@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.contactInfo {
  display: flex;
  flex-direction: column;
  font-size: $fs-title-24;
  text-align: left;
  row-gap: $m-8;

  @include respond(phone) {
    font-size: $fs-body-14;
  }

  .contactLink {
    @include style-link($color-white);

    &:hover {
      color: $color-primary;
    }
  }

  .contact {
    color: $color-disabled;
  }
}
