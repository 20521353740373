@import '../../../../../app/styles/mixins';
@import '../../../../../app/styles/variables';

.mainBannerSkelleton {
  height: 512px;
  width: 100%;
  border-radius: $radius-24;
  background-color: $color-disabled;
  border: 2px solid $color-disabled;
  opacity: 0.1;
}
