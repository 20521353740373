@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.title {
  text-align: center;
  margin-bottom: $m-72;

  @include respond(phone) {
    margin-bottom: $m-24;
  }
}
