@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.itemDiscriptionTitle {
  margin-bottom: $m-16;
}

.itemDiscriptionLi {
  display: flex;
  align-items: center;
  column-gap: $m-8;
  border-bottom: solid 1px $color-light-gray;
  padding: 12px 0;

  &:first-child {
    border-top: solid 1px $color-light-gray;
  }
}

.secondaryColor {
  color: $color-disabled;
}
