@import '../../app/styles/mixins';
@import '../../app/styles/variables';

.form {
  display: flex;
  flex-direction: column;
  gap: $m-32;
}

.inputsContainer {
  display: grid;
  row-gap: $m-16;
  width: 100%;
}

.inputContainer {
  display: grid;
  gap: $m-8;
}

.input {
  background-color: transparent;
  padding: $m-16 0;
  color: $color-white;
  width: 100%;
  border-bottom: 1px solid $color-white;
  font-size: $fs-body-16;

  &:focus {
    border-bottom: 1px solid $color-primary;
  }
}

.error {
  color: $color-red;
}

.tip {
  text-align: center;
}

.tipLink {
  text-decoration: none;
  color: $color-disabled;
  cursor: pointer;
  transition: all 200ms;
}

.tipLink:hover {
  border-bottom: 1px solid $color-white;
  padding-bottom: 3px;
  color: $color-white;
}

.resendCode {
  color: $color-disabled;
  cursor: pointer;
  transition: all 200ms;
  text-decoration: none;
}

.resendCode:hover {
  border-bottom: 1px solid $color-white;
  padding-bottom: 3px;
  color: $color-white;
}

.resendCodeВDisabled {
  color: $color-disabled;
}

.alignCenter {
  text-align: center;
}

.buttonsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $m-16;

  @include respond(tab-phone) {
    grid-template-columns: unset;
  }
}
