@import '../../app/styles/mixins';
@import '../../app/styles/variables';

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  width: 410px;
  padding: $m-40 $m-40;

  border-radius: $radius-16;
  background-color: $color-light-gray;

  @include respond(phone) {
    width: 90vw;
    padding: $m-24;
  }
}

.title {
  margin-bottom: $m-40;
  text-align: center;

  @include respond(tab-port) {
    margin-bottom: $m-24;
  }
}

.text {
  margin-bottom: $m-24;
  word-break: keep-all;
  text-align: center;
}

.changeNumberLink {
  color: $color-primary;
  text-decoration: none;
}
