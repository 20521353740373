@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.itemButtons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $m-24;
  // Хочется еще оттолкнуть блок с дропдаунами
  margin-bottom: $m-16;

  @include respond(tab-port) {
    display: flex;
    flex-direction: column;
    gap: $m-16;
  }
}
