@import '../../../../../../../app/styles/mixins';
@import '../../../../../../../app/styles/variables';

.modalContent {
  position: fixed;
  bottom: $m-24;
  right: $m-24;
  width: 580px;
  padding: $m-24;
  border-radius: $radius-16;
  background-color: $color-gray;
  pointer-events: all;
  z-index: 2100;

  -webkit-box-shadow: 0px 0px 14px 8px rgba(220, 220, 220, 0.05);
  -moz-box-shadow: 0px 0px 14px 8px rgba(220, 220, 220, 0.05);
  box-shadow: 0px 0px 14px 8px rgba(220, 220, 220, 0.05);

  @include respond(tab-phone) {
    width: 70vw;
    font-size: $fs-body-14;
    margin: 0 auto;
  }

  @include respond(tab-phone) {
    width: 70vw;
    font-size: $fs-body-14;
    right: 0;
    left: 0;
  }

  @include respond(phone) {
    border-radius: $radius-16 $radius-16 0 0;
    width: 100vw;
    bottom: 0;
  }
}

.title {
  margin-bottom: $m-16;
  text-align: center;

  @include respond(tab-port) {
    margin-bottom: $m-24;
  }
}

.message {
  text-align: center;
  margin-bottom: $m-24;
}
