@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.mySwiper {
  display: flex;

  .swiper-slide {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.mySwiperSmall {
  .swiper-slide {
    height: 100px;
    min-width: 100px;
    border: 2px solid $color-light-gray;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
    transition: all 300ms ease;

    &:hover {
      border: 2px solid $color-white;
    }

    &-thumb-active {
      border: 2px solid $color-disabled;
      border-radius: 8px;
    }

    @include respond(tab-phone) {
      border: 1px solid $color-light-gray;
      height: 80px;
      min-width: 80px;
      max-width: 80px;

      &:hover {
        border: 1px solid $color-white;
      }

      &-thumb-active {
        border: 1px solid $color-disabled;
        border-radius: 8px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}
