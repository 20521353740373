@import '../../../app/styles/variables';
@import '../../../app/styles/mixins';

.aboutPage {
  row-gap: $m-72;

  @include respond(phone) {
    row-gap: $m-32;
  }
}

.titleAbout {
  text-align: center;
  margin-bottom: $m-72;

  @include respond(tab-phone) {
    margin-bottom: $m-40;
  }
}
