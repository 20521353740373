@import '../../../../app/styles/variables';
@import '../../../../app/styles/mixins';

.vacancyItem {
  white-space: pre-wrap;

  &:not(:last-child) {
    margin-bottom: 120px;
  }
}

.vacancyTitle {
  text-align: center;
  margin-bottom: 50px;
}

.vacancySubtitle {
  letter-spacing: 0.5px;
  color: $color-white;
  text-align: center;
  margin-bottom: 80px;
}

.itemTitle {
  width: 80%;
  text-align: center;
  margin: 0 auto 60px;
  color: $color-primary;
}

.itemContainer {
  display: grid;
  grid-template-columns: repeat(2, 40%);
  justify-content: space-between;
  gap: $m-24;

  @include respond(tab-phone) {
    grid-template-columns: none;

    .itemImage {
      order: 1;
      justify-self: center;
    }

    .itemInfo {
      order: 2;
    }
  }
}

.itemImage {
  justify-self: end;
  width: 100%;
}

.itemInfoBox {
  margin-bottom: 40px;
}

.itemInfoTitle {
  margin-bottom: 20px;
}

.itemInfoTitleMainColor {
  color: $color-primary;
}

.itemInfoList {
  list-style: none;
  color: $color-white;
  line-height: 130%;
}

.itemInfoItem {
  letter-spacing: 0.5px;
  &:not(:last-child) {
    margin-bottom: 6px;
  }
}

.itemInfoContacts {
  display: grid;
  gap: $m-8;
}

.itemTel {
  display: block;
  text-decoration: none;
  color: $color-white;
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &:hover {
    color: $color-primary;
  }
}

.itemEmail {
  display: block;
  text-decoration: none;
  color: $color-white;
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &:hover {
    color: $color-primary;
  }
}
