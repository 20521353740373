@import '../../../../app/styles/variables';
@import '../../../../app/styles/mixins';

.socialMedia {
  display: flex;
  justify-content: flex-end;
  column-gap: $m-24;

  @include respond(tab-phone) {
    justify-content: center;
  }

  .icon {
    cursor: pointer;

    path {
      transition: all 0.2s;
    }
  }

  .icon:hover {
    path {
      fill: $color-primary;
    }
  }
}
