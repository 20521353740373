@import '../../app/styles/mixins';
@import '../../app/styles/variables';

.storePage {
  display: grid;
  row-gap: $m-72;

  @include respond(tab-port) {
    row-gap: $m-40;
  }
}

.storeTitle {
  text-align: center;
}

.storeContainer {
  background-color: $color-light-gray;
  border-radius: $radius-24;
  height: 512px;
  display: grid;
  grid-template-columns: 35% auto;
  overflow: hidden;

  @include respond(tab-land) {
    grid-template-columns: 45% auto;
  }

  @include respond(tab-port) {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    row-gap: $m-40;
  }
}

.repairTimeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addresses {
  overflow: hidden;
  padding: $m-48 $m-32;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include respond(tab-port) {
    row-gap: $m-72;
  }

  @include respond(tab-phone) {
    padding: 0 $m-16 $m-48;
  }
}

.info {
  display: flex;
  flex-direction: column;
  row-gap: $m-32;
}

.btn {
  width: 50%;
  height: 48px;

  @include respond(big-screen) {
    width: 70%;
  }

  @include respond(tab-land) {
    width: 100%;
  }

  @include respond(tab-port) {
    width: 50%;
    margin-bottom: $m-32;
  }

  @include respond(tab-phone) {
    width: 100%;
    margin-bottom: $m-32;
  }
}
