@import '../../../../../../../app/styles/mixins';
@import '../../../../../../../app/styles/variables';

.modalActive {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(55, 65, 81, 0.4);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  z-index: 2100;
  overflow: hidden;
}

.modalContent {
  width: 500px;
  padding: $m-32 $m-32 $m-48;
  border-radius: $radius-16;
  background-color: $color-gray;
  display: flex;
  flex-direction: column;
  z-index: 2100;
  pointer-events: all;

  @include respond(tab-phone) {
    width: 70vw;
    font-size: $fs-body-14;
    padding: $m-24;
  }

  @include respond(phone) {
    position: fixed;
    border-radius: $radius-16 $radius-16 0 0;
    width: 100vw;
    bottom: 0;
  }
}

.closeSpanContainer {
  margin-left: auto;
  margin-bottom: $m-24;
}

.title {
  margin-bottom: $m-16;
  text-align: center;

  @include respond(tab-port) {
    margin-bottom: $m-24;
  }
}

.message {
  text-align: center;
  margin-bottom: $m-24;
}
