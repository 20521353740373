@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.filledCart {
  p {
    line-height: 130%;
  }

  h3 {
    font-weight: 400;
  }
}

.mainContainer {
  display: grid;
  grid-template-columns: auto 30%;
  column-gap: $m-32;

  @include respond(tab-land) {
    grid-template-columns: auto 32%;
  }

  @include respond(tab-port) {
    display: flex;
    flex-direction: column;
    row-gap: $m-40;
  }

  @include respond(tab-phone) {
    row-gap: $m-32;
  }
}

.title {
  text-align: center;
  margin-bottom: $m-72;

  @include respond(tab-port) {
    margin-bottom: $m-40;
  }

  @include respond(tab-phone) {
    margin-bottom: $m-32;
  }
}

.mainModulesContainer {
  display: flex;
  flex-direction: column;
  row-gap: $m-72;

  @include respond(tab-port) {
    row-gap: $m-40;
  }

  @include respond(tab-phone) {
    row-gap: $m-32;
  }
}

.emptyCartContainer {
  display: flex;
  gap: $m-32;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & a {
    width: fit-content;
    margin: 0 auto;
  }
}
