@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.searchWrapper {
  position: relative;
}

.mainIcon {
  transition: all 200ms ease-in-out;
  color: $color-white;
  cursor: pointer;

  &:hover {
    color: $color-primary;
  }
}

.formWrapper {
  top: -8px;
  right: -4px;
  position: absolute;
}

.searchForm {
  position: relative;
}

.icon {
  color: $color-black;
  position: absolute;
  left: $m-12;
  top: 10px;
  cursor: pointer;
}

.searchInput {
  color: $color-black;
  width: 180px;
  border-radius: $radius-16;
  background-color: $color-white;
  border: 1px solid $color-black;
  padding: $m-8 28px $m-8 36px;
}

.closeButton {
  position: absolute;
  color: $color-black;
  right: $m-12;
  top: 10px;
  cursor: pointer;
}
