@import '../../../../../app/styles/mixins';
@import '../../../../../app/styles/variables';

.getOrderSkelletons {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: $m-24;
}

.getOrderSkelleton {
  height: 350px;
  width: 100%;
  border-radius: $radius-24;
  background-color: $color-disabled;
  border: 2px solid $color-disabled;
  opacity: 0.1;

  @include respond(tab-phone) {
    height: 270px;
    border-radius: $radius-16;
  }
}
