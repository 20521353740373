@import '../../../../../../app/styles/mixins';
@import '../../../../../../app/styles/variables';

.widgetContainer {
  width: 100%;
  height: 640px;
  border-radius: $radius-16;
  overflow: hidden;

  @include respond(tab-port) {
    height: 520px;
    border-radius: $radius-8;
  }
}

.widget {
  width: 100%;
  height: 600px;

  @include respond(tab-port) {
    height: 480px;
  }
}
