@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.orderItem {
  background-color: $color-light-gray;
  border-radius: $radius-24;
  padding: $m-48 $m-32;
  display: flex;
  flex-direction: column;
  gap: $m-48;

  @include respond(tab-phone) {
    padding: $m-16;
    gap: $m-32;
    border-radius: $radius-16;
  }
}

.orderHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.orderSummContainer {
  display: flex;
  align-items: center;
  gap: $m-8;
}

.orderSummStatus {
  color: $color-white;
}

.orderSumm {
  color: $color-primary;
}

.orderInfo {
  display: grid;
  gap: $m-24;

  @include respond(tab-phone) {
    gap: $m-16;
  }
}

.orderStatusContainer {
  display: flex;
  gap: $m-16;
  align-items: center;
}

.orderImages {
  display: flex;
  column-gap: $m-16;
  overflow: hidden;

  img {
    width: 90px;
    height: 90px;
    border-radius: $radius-8;
  }
}

.payButton {
  margin-top: auto;
  max-width: 360px;
}

.moreInfoButton {
  color: $color-disabled;
  width: fit-content;
  cursor: pointer;

  &:hover {
    color: $color-white;
  }
}
