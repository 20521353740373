@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

// Нужна вложенность, чтобы стили применялись корректно
.tooltipContainer {
  .tooltip {
    max-width: 300px;
    background-color: $color-white;
    color: $color-black;
    border-radius: $m-16;
    padding: $m-16;

    @include respond(tab-phone) {
      max-width: calc(100% - $m-32);
    }
  }
}

.hintIcon {
  cursor: help;
  width: 24px;
  height: 24px;

  &:hover {
    path {
      fill: $color-primary;
    }
  }
}
