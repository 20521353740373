@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.offersContainer {
  display: grid;
  max-height: 550px;
  margin-top: $m-24;
  overflow: auto;

  @include respond(tab-phone) {
    max-height: 400px;
  }
}

.offerList {
  display: flex;
  column-gap: $m-24;
  padding: $m-16 0;
  transition: all 0.3s;

  &:hover {
    color: $color-primary;
  }
}

.offerListComtainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: $m-16 $m-24;
}

.linkContainer {
  text-decoration: none;
  color: $color-white;
}
