@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.notFoundPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 40dvh;
  border-radius: 16px;
  gap: $m-40;

  @include respond(tab-phone) {
    gap: $m-32;
  }
}

.info {
  text-align: center;
  max-width: 500px;
  color: $color-disabled;

  @include respond(tab-phone) {
    width: 90%;
  }
}

.buttons {
  display: flex;
  column-gap: $m-16;
}
