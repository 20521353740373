@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.iconsContainer {
  position: relative;
}

.indicator {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 100%;
  border: 2px solid $color-gray;
}

.filterIcon {
  cursor: pointer;

  &:hover svg {
    path {
      fill: $color-primary;
    }
  }
}
