@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.page {
  display: flex;
  flex-direction: column;
  row-gap: $m-40;
}

.mainTitle {
  text-align: center;
}

.paragraph {
  line-height: 120%;
  letter-spacing: 0.3px;
}

.listTitle {
  margin-bottom: $m-16;
}

.list {
  list-style: decimal;
  line-height: 120%;
  letter-spacing: 0.3px;
  padding-left: $m-16;
  display: grid;
  row-gap: $m-8;
}
