@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.itemInfo {
  display: grid;
  gap: $m-40;

  @include respond(tab-land) {
    gap: $m-24;
  }
}

.itemTitle {
  font-size: $fs-title-40;

  @include respond(tab-phone) {
    font-size: $fs-title-24;
  }
}

.pricesContainer {
  display: grid;
  gap: $m-8;
}

.prices {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: $m-16;
}

.mainPrice {
  font-size: $fs-title-32;

  @include respond(tab-phone) {
    font-size: $fs-title-24;
  }
}

.mainPricePrimary {
  color: $color-primary;
  font-size: $fs-title-40;

  @include respond(tab-phone) {
    font-size: $fs-title-24;
  }
}

.oldPrice {
  color: $color-white;
  text-decoration: line-through;
}

.isManelyakMessage {
  width: fit-content;
  border-radius: $radius-16;
  border: 2px solid $color-primary;
  padding: $m-8 $m-24;

  @include respond(tab-phone) {
    padding: $m-8 $m-16;
  }
}
