@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.paginationContainer {
  margin-top: $m-48;
  display: flex;
  justify-content: center;

  @include respond(tab-phone) {
    margin-top: $m-24;
  }

  .paginationItem {
    cursor: pointer;
    transition: all 0.15s ease;
    padding: 6px 12px;
  }

  .activePaginationItem {
    cursor: default;
    border-bottom: 1px solid $color-primary;
  }
}
