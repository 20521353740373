@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.container {
  background-blend-mode: multiply;
  background: $color-gray url(../../../../public/img/background.webp) center /
    cover no-repeat;
  padding: $m-72 $m-40;
  border-radius: $radius-24;

  @include respond(tab-land) {
    padding: $m-72 $m-40;
  }

  @include respond(tab-port) {
    padding: $m-72 $m-32;
  }

  @include respond(tab-phone) {
    padding: $m-40 $m-16;
  }

  @include respond(phone) {
    padding: $m-32 $m-8;
  }
}
