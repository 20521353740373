@import '../../../app/styles/variables';
@import '../../../app/styles/mixins';

.footer {
  padding: $m-48 $m-40;

  @include respond(tab-port) {
    padding: $m-48 $m-32;
  }

  @include respond(tab-phone) {
    padding: $m-24 $m-8;
  }

  .container {
    display: flex;
    justify-content: space-between;
    height: 277px;

    @include respond(tab-phone) {
      display: block;
      height: auto;
    }

    .navbarSection {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      @include respond(tab-phone) {
        row-gap: $m-24;
        justify-content: center;
        align-items: center;
        margin-bottom: $m-24;
      }
    }

    .contactsSection {
      font-size: $fs-body-14;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .socialMedia {
        padding-top: 20px;

        @include respond(tab-phone) {
          padding-top: 0;
          margin-bottom: $m-24;
          row-gap: $m-16;
        }
      }
    }
  }
}
