@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.cabinetNavbar {
  display: flex;
  flex-direction: column;
  height: fit-content;
  background-color: $color-light-gray;
  border-radius: $radius-24;
  padding: $m-48 $m-32;

  @include respond(tab-port) {
    padding: $m-48 $m-32;
  }

  @include respond(phone) {
    padding: $m-16;
    border-radius: $radius-16;
  }
}

.navbarUserInfo {
  display: grid;
  gap: $m-8;
  padding-bottom: $m-8;
  margin-bottom: $m-32;
  border-bottom: 1px solid $color-white;

  @include respond(tab-port) {
    width: fit-content;
    margin-bottom: $m-24;
  }
}

.fioSpan {
  color: $color-disabled;
}

.navbarUserFio {
  width: fit-content;
  text-decoration: none;
  color: $color-white;
  font-size: $fs-title-24;

  &:hover {
    color: $color-primary;
  }
}

.navbarUserFioActive {
  width: fit-content;
  text-decoration: none;
  color: $color-primary;
  font-size: $fs-title-24;
}

.navbarList {
  display: flex;
  flex-direction: column;
  row-gap: $m-24;

  @include respond(tab-port) {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: $m-16;
  }
}

.navbarListItem {
  width: fit-content;
  text-decoration: none;
  color: $color-white;

  &:hover {
    color: $color-primary;
  }
}

.active {
  width: fit-content;
  text-decoration: none;
  color: $color-primary;
  cursor: default;
}
