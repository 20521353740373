@import '../../../../../app/styles/mixins';
@import '../../../../../app/styles/variables';

.catalogSkelletonsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: $m-24;

  @include respond(tab-phone) {
    grid-template-columns: repeat(auto-fill, minmax(156px, 1fr));
    gap: $m-8;
  }
}

.catalogSkelleton {
  position: relative;
  height: 512px;
  border-radius: $radius-16;
  background-color: $color-disabled;
  opacity: 0.1;

  @include respond(tab-phone) {
    height: 350px;
  }
}
