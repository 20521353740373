@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.title {
  text-align: center;
  margin-bottom: $m-72;

  @include respond(tab-port) {
    margin-bottom: $m-40;
  }
}

.message {
  text-align: center;
}

.bannersContainer {
  display: flex;
  flex-direction: column;
  row-gap: $m-72;
}

.list {
  display: flex;
  gap: $m-24;
  transition: 500ms;

  button {
    color: $color-white;
    cursor: pointer;
  }

  button:hover {
    color: $color-disabled;
  }

  button:focus {
    color: $color-primary;
    cursor: default;
  }
}

.bannerSecond {
  display: grid;
  gap: $m-24;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: dense;

  @include respond(tab-land) {
    grid-template-columns: 1fr 1fr;
  }

  @include respond(tab-phone) {
    grid-template-columns: 1fr;
  }
}

.horizontal {
  @include respond(tab-land) {
    grid-row: span 1;
    grid-column: span 2;
  }

  @include respond(tab-phone) {
    grid-row: auto;
    grid-column: auto;
  }
}

.textSecond {
  line-height: $m-24;
}

.bannerThird {
  display: grid;
  column-gap: $m-32;
  grid-template-columns: 57.55% auto;

  @include respond(tab-land) {
    grid-template-columns: 1fr 1fr;
  }

  @include respond(tab-phone) {
    grid-template-columns: 1fr;
  }
}

.bannerFourth {
  display: grid;
  column-gap: $m-32;
  grid-template-columns: 1fr 1fr;

  @include respond(tab-phone) {
    grid-template-columns: 1fr;
  }
}
