@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.address {
  display: flex;
  row-gap: $m-8;
  flex-direction: column;
}

.addressInfo {
  display: flex;
  justify-content: space-between;
}

.addressLink {
  text-decoration: none;
  color: $color-white;
  transition: all 0.25s;

  &:hover {
    color: $color-primary;
  }
}

.activeLink {
  text-decoration: none;
  color: $color-primary;
}
