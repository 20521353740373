@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.container {
  height: 512px;
  display: grid;
  grid-gap: 2px;
  grid-template-columns: 50% 1fr 1fr;
  grid-template-rows: 256px;
  grid-auto-flow: dense;
  border-radius: $radius-16;
  overflow: hidden;

  @include respond(tab-port) {
    grid-template-columns: 1fr 1fr;
  }
}

.vertical {
  grid-row: span 2;

  @include respond(tab-port) {
    grid-row: span 1;
    grid-column: span 2;
  }
}

.horizontal {
  grid-column: span 1;
  background-position: right;

  @include respond(tab-port) {
    grid-row: span 2;
  }
}

.firstPhoto {
  background: url(../../../../public/img/gallery-img/photo1.webp) no-repeat;
  background-size: cover;
  background-position: 0 50%;

  @include respond(tab-land) {
    background-position: 50% 50%;
  }

  @include respond(tab-port) {
    background-position: 0 60%;
  }
}

.secondPhoto {
  background: url(../../../../public/img/gallery-img/photo2.webp) no-repeat;
  background-size: cover;
  background-position: 100% 0;

  @include respond(tab-phone) {
    background-position: 80% 0;
  }
}

.thirdPhoto {
  background: url(../../../../public/img/gallery-img/photo3.webp) no-repeat;
  background-size: cover;
  background-position: 50% 0;
}

.fourthPhoto {
  background: url(../../../../public/img/gallery-img/photo4.webp) no-repeat;
  background-size: cover;
  background-position: 0 60%;
}
