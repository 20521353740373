@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.watsappButton {
  padding: $m-12;
  background-color: #3cb84b;
  color: $color-gray;
  border-radius: $radius-4;
  width: 100%;
  height: min-content;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0px 0px 0px 2px $color-white inset;
  }

  &:active {
    box-shadow: none;
  }

  @include respond(tab-phone) {
    font-size: $fs-body-14;
    padding: 10px $m-8;
  }
}
