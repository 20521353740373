@import '../../../../../app/styles/mixins';
@import '../../../../../app/styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.cartSkelletonsContainer {
  display: grid;
  grid-template-columns: auto 30%;
  column-gap: $m-32;

  @include respond(tab-land) {
    grid-template-columns: auto 32%;
  }

  @include respond(tab-port) {
    display: flex;
    flex-direction: column;
    row-gap: $m-40;
  }

  @include respond(tab-phone) {
    row-gap: $m-32;
  }
}

.cartSkelleton {
  display: grid;
  row-gap: $m-72;

  @include respond(tab-port) {
    row-gap: $m-40;
  }

  @include respond(tab-phone) {
    row-gap: $m-32;
  }
}

.title {
  align-self: center;
  margin-bottom: $m-72;

  @include respond(tab-port) {
    margin-bottom: $m-40;
  }

  @include respond(tab-phone) {
    margin-bottom: $m-32;
  }
}

.cartItems,
.cartReciev {
  width: 100%;
  height: 500px;
  border-radius: $radius-16;
  background-color: $color-disabled;
  opacity: 0.1;

  @include respond(tab-phone) {
    height: 350px;
  }
}

.total {
  width: 100%;
  height: 350px;
  border-radius: $radius-16;
  background-color: $color-disabled;
  opacity: 0.1;
}
