@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.warningBadge {
  text-decoration: none;
  color: $color-white;
  background-color: $color-red;
  border-radius: $radius-4;
  padding: $m-8;

  @include respond(tab-phone) {
    font-size: $fs-body-12;
  }
}
