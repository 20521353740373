@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.privacyPolictContainer {
  color: $color-disabled;
  font-size: $fs-body-12;

  a {
    color: $color-disabled;
    transition: all 0.25s;

    &:hover {
      color: $color-white;
    }
  }
}
