@import '../../../../../../app/styles/mixins';
@import '../../../../../../app/styles/variables';

.errorsContainer {
  display: flex;
  flex-direction: column;
  row-gap: $m-8;
}

.error {
  font-size: $fs-body-14;
  letter-spacing: 0.01cm;
  color: #a42121;
}
