@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.videoPlayerCard {
  width: 100%;
  height: 100%;
  transition: all 300ms ease;
  position: relative;
}

.playIcon {
  position: absolute;
  z-index: 1;
  width: 180px;
  height: 180px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include respond(tab-phone) {
    width: 140px;
    height: 140px;
  }

  & path {
    fill: $color-white;
    stroke: $color-white;
    transition: all 300ms ease;
    cursor: pointer;
  }

  & path:hover {
    fill: $color-primary;
    stroke: $color-primary;
  }
}

.img {
  width: 100%;
  height: 100%;
}
