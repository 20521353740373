@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.form {
  display: grid;
  width: 100%;
  row-gap: $m-16;
}

.inputContainer {
  display: grid;
  gap: $m-8;
}

.input {
  background-color: transparent;
  padding: $m-16 0;
  color: $color-white;
  width: 100%;
  border-bottom: 1px solid $color-white;
  font-size: $fs-body-16;

  &:focus {
    border-bottom: 1px solid $color-primary;
  }
}

.error {
  color: rgb(164, 33, 33);
}

.checkboxContainer {
  margin-bottom: $m-24;
}

.buttonsContainer {
  display: flex;
  gap: $m-8;
}

.alignCenter {
  text-align: center;
}
