@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.container {
  display: grid;
  gap: $m-48;

  @include respond(tab-port) {
    gap: $m-48;
  }

  @include respond(phone) {
    gap: $m-24;
  }
}

.team {
  display: grid;
  row-gap: $m-72;

  @include respond(phone) {
    row-gap: $m-32;
  }
}

.memberTeamLeft {
  display: flex;
  gap: $m-32;

  @include respond(tab-port) {
    row-gap: $m-16;
  }

  @include respond(tab-phone) {
    flex-direction: column;
  }

  &:hover {
    .imgContainer img {
      transition: all 0.2s ease-in;
      filter: none;
    }
  }
}

.memberTeamRight {
  display: flex;
  flex-direction: row-reverse;
  gap: $m-32;

  @include respond(tab-phone) {
    row-gap: $m-16;
  }

  @include respond(tab-phone) {
    flex-direction: column;
  }

  &:hover {
    .imgContainer img {
      transition: all 0.2s ease-in;
      filter: none;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-self: center;
  row-gap: $m-8;
  width: 50%;

  @include respond(tab-phone) {
    width: 100%;

    h3 {
      font-size: 20px;
    }
  }
}

.imgContainer {
  height: 819px;

  @include respond(tab-port) {
    height: 608px;
  }

  @include respond(tab-phone) {
    height: 781px;
  }

  @include respond(phone) {
    height: 364px;
  }
}

.memberPhoto {
  height: 100%;
  object-fit: contain;
  filter: grayscale(100%);
  transition: all 0.2s ease;

  @include respond(tab-phone) {
    object-fit: cover;
    object-position: 0 0;
    width: 100%;
  }
}
