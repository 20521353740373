@import '../../../../../../app/styles/mixins';
@import '../../../../../../app/styles/variables';

.container {
  display: grid;
  gap: $m-24;

  @include respond(tab-phone) {
    display: grid;
    text-align: center;
    gap: $m-24;
  }
}
