@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.jewelryPage {
  display: flex;
  flex-direction: column;
  row-gap: $m-72;

  @include respond(phone) {
    row-gap: $m-32;
  }

  P {
    line-height: $m-24;

    @include respond(tab-phone) {
      line-height: $m-16;
    }
  }
}
