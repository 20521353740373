//ЦВЕТА САЙТА
$color-white: #dcdcdc;
$color-black: #000000;
$color-disabled: #848484;
$color-primary: #dbb153;
$color-gray: #111111;
$color-light-gray: #1d1d1d;
$color-red: #d03535;

//ТИПОГРАФИКА
$fs-title-40: 40px;
$fs-title-32: 32px;
$fs-title-24: 24px;
$fs-body-16: 16px;
$fs-body-14: 14px;
$fs-body-12: 12px;

//ОТСТУПЫ
$m-8: 8px;
$m-12: 12px;
$m-14: 14px;
$m-16: 16px;
$m-24: 24px;
$m-32: 32px;
$m-40: 40px;
$m-48: 48px;
$m-64: 64px;
$m-72: 72px;

//РАДИУСЫ
$radius-24: 24px;
$radius-16: 16px;
$radius-8: 8px;
$radius-4: 4px;
