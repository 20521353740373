@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.profile {
  display: grid;
  grid-template-columns: 300px auto;
  gap: $m-32;

  @include respond(tab-port) {
    display: flex;
    flex-direction: column;
    gap: $m-32;
  }
}
