@import '../../../../../app/styles/mixins';
@import '../../../../../app/styles/variables';

.totalContainer {
  height: fit-content;
  position: sticky;
  top: $m-16;
  display: flex;
  flex-direction: column;
  row-gap: $m-32;
  padding: $m-48 $m-32;
  background-color: $color-light-gray;
  border-radius: $radius-24;

  @include respond(tab-land) {
    padding: $m-48 $m-24;
  }

  @include respond(tab-port) {
    padding: $m-48 $m-32;
    position: static;
    border-radius: $radius-16;
  }

  @include respond(tab-phone) {
    padding: $m-24 $m-16;
  }
}

.btnsContainer {
  display: flex;
  flex-direction: column;
  gap: $m-16;
}

.attention {
  font-size: $m-14;
  color: $color-disabled;

  @include respond(tab-phone) {
    font-size: $m-12;
  }
}

.totalSkelleton {
  width: 100%;
  height: 350px;
  border-radius: $radius-16;
  background-color: $color-disabled;
  opacity: 0.1;
}
