@import '../../../../app/styles/variables';
@import '../../../../app/styles/mixins';

.userInfo {
  display: grid;
  gap: $m-32;
  height: fit-content;
  background-color: $color-light-gray;
  border-radius: $radius-24;
  padding: $m-48 $m-32;

  @include respond(phone) {
    padding: $m-16;
    border-radius: $radius-16;
    gap: $m-16;
  }
}

/* ФОРМА */

.form {
  display: grid;
  row-gap: $m-32;
  width: 400px;

  @include respond(phone) {
    width: 100%;
    row-gap: $m-24;
  }
}

.inputsContainer {
  display: grid;
  gap: $m-16;

  @include respond(phone) {
    gap: $m-8;
  }
}

.inputContainer {
  display: grid;
  gap: $m-8;
}

.input {
  background-color: transparent;
  padding: $m-16 0;
  color: $color-white;
  width: 100%;
  border-bottom: 1px solid $color-white;

  &:focus {
    border-bottom: 1px solid $color-primary;
  }
}

.error {
  color: rgb(164, 33, 33);
}
