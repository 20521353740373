@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.cartItem {
  display: grid;
  grid-template-columns: 280px auto 24px;
  grid-template-rows: 280px;
  column-gap: $m-16;
  padding: $m-24 0;
  border-bottom: 1px solid $color-white;

  @include respond(tab-land) {
    grid-template-columns: 160px auto 24px;
    grid-template-rows: 160px;
  }

  @include respond(tab-phone) {
    grid-template-columns: 120px auto 24px;
    grid-template-rows: 120px;
    column-gap: $m-8;
    padding: $m-16 0;
  }
}

.cartItemImgContainer {
  display: flex;
  justify-content: center;
  justify-items: center;
  width: 100%;
  border-radius: $radius-8;
  overflow: hidden;
  position: relative;
}

.cartItemImg {
  width: 100%;
  object-fit: contain;
}

.badge {
  position: absolute;
  z-index: 2;
  left: 20px;
  top: 20px;

  @include respond(tab-land) {
    top: 10px;
    left: 10px;
  }
}

.cartItemInfo {
  display: flex;
  flex-direction: column;
  row-gap: $m-12;
}

.cartItemLinkTitle {
  color: $color-white;
  text-decoration: none;
  font-size: $fs-title-24;

  &:hover {
    text-decoration: underline;
  }

  @include respond(tab-land) {
    overflow: hidden;
    height: 28px;
  }

  @include respond(tab-phone) {
    font-size: $fs-body-16;
    height: 18px;
  }
}

.cartItemPrices {
  display: flex;
  align-items: baseline;
  column-gap: $m-16;

  @include respond(tab-phone) {
    column-gap: $m-8;
  }
}

.cartItemPrice {
  color: $color-primary;
}

.cartItemOldPrice {
  text-decoration: line-through;

  @include respond(tab-phone) {
    font-size: $fs-body-12;
  }
}

.cartItemDopInfo {
  display: flex;
  column-gap: $m-16;
  margin-top: auto;
  color: $color-disabled;

  @include respond(tab-land) {
    p {
      font-size: $fs-body-14;
    }
  }

  @include respond(tab-phone) {
    p {
      font-size: $fs-body-12;
    }
  }
}

/* ИКОНКИ */

.cartIcons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
}

.favoriteIcon,
.trashIcon {
  cursor: pointer;

  &:hover {
    path {
      fill: $color-primary;
    }
  }
}

.isManelyakMessage {
  font-size: $fs-body-12;
  width: fit-content;
  border-radius: $radius-16;
  border: 2px solid $color-primary;
  padding: $m-8 $m-16;

  @include respond(tab-phone) {
    font-size: 10px;
    border: 1px solid $color-primary;
    border-radius: $radius-8;
    padding: 4px $m-8;
  }
}
