@import '../../../../app/styles/variables';

.linkButton {
  height: fit-content;
  a {
    text-decoration: none;
    color: $color-white;
    cursor: pointer;
    transition: all 0.2s;
  }

  a:hover {
    border-bottom: 1px solid $color-primary;
    padding-bottom: 4px;
  }
}
