@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.catalogTitleContainer {
  display: flex;
  column-gap: $m-16;
  margin-bottom: $m-72;
  justify-content: center;

  @include respond(tab-phone) {
    flex-direction: column;
    margin-bottom: $m-24;
    row-gap: $m-8;
    align-items: center;

    h1 {
      text-align: center;
    }
  }
}

.catalogDescription {
  text-align: center;
  margin-top: $m-48;
}
