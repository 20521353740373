@import '../../../app/styles/mixins';
@import '../../../app/styles/variables';

.favoriteListItems {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  justify-items: safe;
  gap: $m-32;

  @include respond(tab-phone) {
    grid-template-columns: repeat(auto-fill, minmax(156px, 1fr));
    gap: $m-16;
  }
}
