@import '../../../../../app/styles/variables';
@import '../../../../../app/styles//mixins.scss';

.navbar {
  font-size: $fs-body-16;
  display: flex;
  list-style: none;
  column-gap: 24px;
  // Чтобы не прыгали ссылки
  height: 16px;

  @include respond(tab-land) {
    display: none;
  }

  .linkButton {
    height: fit-content;
    text-decoration: none;
    color: $color-white;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      border-bottom: 1px solid $color-primary;
      padding-bottom: 4px;
    }
  }

  .linkButtonActive {
    height: fit-content;
    color: $color-primary;
    text-decoration: none;
  }
}
