@import './reset';
@import './normalize';

@import './variables';
@import './mixins';

body {
  max-width: 1920px;
  margin: 0 auto;

  background-color: $color-black;
  color: $color-white;
  font-size: $fs-body-16;
  letter-spacing: 0.03mm;
  line-height: 1.2;

  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background: $color-black;
}

h1 {
  font-size: $fs-title-40;

  @include respond(phone) {
    font-size: $fs-title-24;
  }
}

h2 {
  font-size: $fs-title-32;

  @include respond(tab-phone) {
    font-size: $fs-title-24;
  }
}

h3 {
  font-size: $fs-title-24;

  @include respond(tab-phone) {
    font-size: $fs-body-16;
  }
}

p {
  font-size: $fs-body-16;

  @include respond(tab-phone) {
    font-size: $fs-body-14;
  }
}

span,
a,
p,
li,
td,
tr,
button {
  letter-spacing: 0.5px;
  line-height: 130%;
}

button {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: none;
  border: 0;
  border-radius: 0;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0;
  border-radius: 0;

  //Скрываю стрелки
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

// СКРОЛЛБАР
::-webkit-scrollbar {
  width: $m-8;
}

// Бегунок скроллбара
::-webkit-scrollbar-thumb {
  background-color: $color-white;
  border-radius: 5px;
}

// Трек скроллбара
::-webkit-scrollbar-track {
  background-color: $color-light-gray;
}

.grecaptcha-badge {
  visibility: hidden;
}
