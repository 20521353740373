@import '../../../../app/styles/mixins';
@import '../../../../app/styles/variables';

.category {
  height: 160px;
  width: 160px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  background-color: $color-black;
  padding: 24px 8px;
  border-radius: $radius-16;

  &:hover {
    box-shadow: 0px 0px 0px 2px $color-primary inset;
  }

  @include respond(tab-phone) {
    padding: 14px 4px;
    gap: 4px;
    height: 80px;
    width: 80px;
    border-radius: $radius-8;
  }
}

.imgContainer {
  height: 60%;
}

.categoryImage {
  height: 100%;
}

.categoryText {
  text-align: center;

  @include respond(tab-phone) {
    font-size: $fs-body-12;
  }
}
